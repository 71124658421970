



































































































































































































import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import SessionsModule from '@/store/modules/Sessions/module';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import { AddSessionPayloadV2 } from '@/Model/sessions/types';
import ExpandableSelectList from '@/commoncomponents/ExpandableSelectList/ExpandableSelectList.vue';
import ProgramsModule from '@/store/modules/Programs/module';
import SiteLocationsModule from '@/store/modules/SiteLocations/module';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { GetAllSiteLocationsRequest, SiteLocationResponse, VerifiedSiteLocationResponse, VerifiedSiteLocationResponseLocation } from '@/Model/siteLocations/types';
import TimeComboBox from '@/commoncomponents/TimeComboBox.vue';
import { ProgramData } from '@/Model/programs/types';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import { FormValidationError, VeeValidateProviderMode } from '@/Model/forms/types';
import { ValidationProvider } from 'vee-validate';
import PaymentsModule from '@/store/modules/Payments/module';
import { AnalyticsInjectionKey, AnalyticsService } from '@/analytics';
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import { Dropdown } from '@/ui-components/multiSelectDropdown/MultiSelectDropdown';
import { daysMapping } from '@/utilities/cmsUtilities';

const timeFormatType = 'en-US';

@Component({
  components: {
    DatepickerComponent,
    ExpandableSelectList,
    TimeComboBox,
    ValidationProvider,
    DropdownList,
    MultiSelectDropdown,
  }
})

export default class SessionPanel extends Vue {
  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

  readonly screenText = new ScreenText();
  readonly siteLocationsModule = SiteLocationsModule;
  readonly paymentsModule = PaymentsModule;
  readonly programsModule = ProgramsModule;
  readonly sessionNameMaxLength = 100;
  readonly defaultOpenTime = '8:00 AM';
  private readonly accountId = APP_UTILITIES.getAccountId();

  public sessionDaysDropdown: Dropdown = {
    value: 'Select',
    disable: false,
    search: false,
    placeholder: '',
    dropdownList: Object.values(daysMapping).map((day, index) => ({
      id: index,
      value: day,
      checked: false,
    })),
    checked: false,
    isAllSelect: false,
    selectDropdownCount: true,
    showValues: true,
  };

  sessionData: AddSessionPayloadV2 = {
    programId: undefined,
    sessionName: '',
    sessionDescription: '',
    startDate: null,
    endDate: null,
    startTime: '',
    endTime: '',
    selectedDays: [],
    siteLocationId: undefined,
  };

  hasUserSubmittedForm = false;
  isValidForProgressButton = true;
  isFormDirty = false;
  selectedSite: SiteLocationResponse | VerifiedSiteLocationResponse | null = null;
  isSelectSitePanelOpen: boolean = false;
  sitesExcludedSearchFields:
    Array<keyof SiteLocationResponse | keyof VerifiedSiteLocationResponse | keyof VerifiedSiteLocationResponseLocation> =
    ['accountId', 'addressHash', 'createdAt', 'id', 'locationId', 'locationVerificationId', 'status'];
  sessionsModule = SessionsModule;

  programStartDate: Date = new Date(0);
  programEndDate: Date = new Date(0);

  startTimeError: FormValidationError | null = null;
  startTimeErrorMessage = '';

  endTimeError: FormValidationError | null = null;
  endTimeErrorMessage = '';

  hasStartDateError = false;
  hasEndDateError = false;

  hasSessionDatesExceedProgramError = false;

  get addEditSessionSiteSelectTitle(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_SITE_SELECT_TITLE');
  }

  get addEditSessionSiteSelectSubtitle(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_SITE_SELECT_SUBTITLE');
  }

  get addEditSessionNoSitesText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_NO_SITES_TEXT');
  }

  get startDateErrorMessage(): string {
    return this.screenText.getScreenText('SESSION_ADD_START_DATE_REQUIRED');
  }

  get endDateErrorMessage(): string {
    return this.screenText.getScreenText('SESSION_ADD_END_DATE_REQUIRED');
  }

  get sessionDatesExceedProgramError(): string {
    return this.screenText.getScreenText('SESSION_ADD_DATES_EXCEED_PROGRAM_ERROR');
  }

  get shouldApplyErrorClassForSessionName(): boolean {
    return !!this.sessionsModule.sessionSaveFailedError && this.sessionsModule.sessionSaveFailedError.value === this.screenText.getScreenText('SESSION_NAME_CONFLICT');
  }

  get sessionStartDateMax(): Date | null {
    const sessionEndDate = this.sessionData.endDate;
    if (sessionEndDate && sessionEndDate instanceof Date) {
      return sessionEndDate;
    }
    return null;
  }

  get sessionEndDateMin(): Date | null {
    const sessionStartDate = this.sessionData.startDate;
    if (sessionStartDate && sessionStartDate instanceof Date) {
      return sessionStartDate;
    }
    return null;
  }

  get startDateOpenDate(): Date {
    if (this.sessionData.startDate != null) {
      return this.sessionData.startDate;
    }
    else if (this.sessionData.endDate != null) {
      return this.sessionData.endDate;
    }
    else {
      return new Date();
    }
  }

  get endDateOpenDate(): Date {
    if (this.sessionData.endDate != null) {
      return this.sessionData.endDate;
    }
    else if (this.sessionData.startDate != null) {
      return this.sessionData.startDate;
    }
    else {
      return new Date();
    }
  }

  get startTimeOpenTime(): string {
    if (this.sessionData.startTime) {
      return this.sessionData.startTime;
    }
    else if (this.sessionData.endTime) {
      const endTimeAsDate = new Date(APP_UTILITIES.getFullDate(new Date()) + ' ' + this.sessionData.endTime);
      const minusOneHour = endTimeAsDate.getHours() - 1;
      endTimeAsDate.setHours(minusOneHour >= 0
        ? minusOneHour
        : 0);
      const endTimeAsDateString = endTimeAsDate.toLocaleTimeString(timeFormatType, { hour: 'numeric', hour12: true, minute: 'numeric' });
      return endTimeAsDateString === 'Invalid Date'
        ? this.defaultOpenTime
        : endTimeAsDateString;
    }
    else {
      return this.defaultOpenTime;
    }
  }

  get endTimeOpenTime(): string {
    if (this.sessionData.endTime) {
      return this.sessionData.endTime;
    }
    else if (this.sessionData.startTime) {
      const startTimeAsDate = new Date(APP_UTILITIES.getFullDate(new Date()) + ' ' + this.sessionData.startTime);
      const plusOneHour = startTimeAsDate.getHours() + 1;
      startTimeAsDate.setHours(plusOneHour < 24
        ? plusOneHour
        : 23);
      const startTimeAsDateString = startTimeAsDate.toLocaleTimeString(timeFormatType, { hour: 'numeric', hour12: true, minute: 'numeric' });
      return startTimeAsDateString === 'Invalid Date'
        ? this.defaultOpenTime
        : startTimeAsDateString;
    }
    else {
      return this.defaultOpenTime;
    }
  }

  get hasAnyDateErrors(): boolean {
    this.hasStartDateError = false;
    this.hasEndDateError = false;
    this.hasSessionDatesExceedProgramError = false;

    const hasSessionStartDate = !!this.sessionData.startDate;
    const hasSessionEndDate = !!this.sessionData.endDate;

    if (hasSessionStartDate === false) {
      this.hasStartDateError = true;
    }

    if (hasSessionEndDate === false) {
      this.hasEndDateError = true;
    }

    if (hasSessionStartDate) {
      const currentStartDateTime = new Date(this.sessionData.startDate as Date).getTime();
      const programStartDateTime = this.programStartDate.getTime();
      const isSessionEarlierThanProgramStartDate = currentStartDateTime < programStartDateTime;
      if (isSessionEarlierThanProgramStartDate) {
        this.hasSessionDatesExceedProgramError = true;
      }
    }

    if (hasSessionEndDate) {
      const currentEndDateTime = new Date(this.sessionData.endDate as Date).getTime();
      const programEndDateTime = this.programEndDate.getTime();
      const isSessionLaterThanProgramEndDate = currentEndDateTime > programEndDateTime;
      if (isSessionLaterThanProgramEndDate) {
        this.hasSessionDatesExceedProgramError = true;
      }
    }

    return this.hasStartDateError || this.hasEndDateError || this.hasSessionDatesExceedProgramError;
  }

  get hasTimeError(): boolean {
    return this.startTimeError !== null || this.endTimeError !== null;
  }

  private get isTimeSet(): boolean {
    return (!!this.sessionData.startTime && this.sessionData.startTime !== '') || (!!this.sessionData.endTime && this.sessionData.endTime !== '');
  }

  get validationMode(): VeeValidateProviderMode {
    return this.hasUserSubmittedForm
      ? VeeValidateProviderMode.Lazy
      : VeeValidateProviderMode.Passive;
  }

  checkIfFormIsDirty(): boolean {
    return this.sessionData.sessionName.length > 0
      || this.sessionData.sessionDescription.length > 0
      || this.sessionData.startDate != null
      || this.sessionData.endDate != null
      || this.sessionData.startTime != ''
      || this.sessionData.endTime != ''
      || this.sessionData.selectedDays.length > 0
      || !!this.selectedSite;
  }

  stripTime(date: Date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );
  }

  created() {
    this.fetchAllSiteLocations();
    this.fetchProgramInformation();
  }

  async fetchProgramInformation() {
    const programId = APP_UTILITIES.getCookie('program_id');
    if (programId) {
      const programIdInt = Number.parseInt(programId);
      const programInfo = await this.programsModule.fetchProgramData(programIdInt);
      if (programInfo) {
        this.assignProgramDataValues(programInfo.data);
      }
    }
  }

  async fetchAllSiteLocations() {
    if (this.accountId) {
      const request: GetAllSiteLocationsRequest = {
        id: this.accountId,
        sortField: 'SiteLocationName',
        sortDir: 1
      };
      await this.siteLocationsModule.getAllSiteLocationsForAccount(request);
    }
  }

  assignProgramDataValues(programInfo: ProgramData) {
    this.programStartDate = new Date(programInfo.programDto.startDate);
    this.programEndDate = new Date(programInfo.programDto.endDate);
    this.programStartDate.setHours(0, 0, 0, 0);
    this.programEndDate.setHours(23, 59, 59, 999);
    this.sessionData.programId = programInfo.programDto.id;
  }

  onChangeSessionStartDate(startDate: Date) {
    this.sessionData.startDate = this.stripTime(startDate);
    this.hasStartDateError = false;
  }

  onChangeSessionEndDate(endDate: Date) {
    this.sessionData.endDate = this.stripTime(endDate);
    this.hasEndDateError = false;
  }

  validateStartEndTimes(): void {
    // This is for consistency with the current form validation
    // which doesn't occur until the user has tried to submit the form
    if (!this.hasUserSubmittedForm) {
      return;
    }

    let startTimeFromDate: number | undefined;
    let endTimeFromDate: number | undefined;

    if (this.sessionData.startTime) {
      const startTimeAsDate = new Date(`1970-01-01 ${this.sessionData.startTime}`);
      startTimeFromDate = startTimeAsDate.getTime();
    }

    if (this.sessionData.endTime) {
      const endTimeAsDate = new Date(`1970-01-01 ${this.sessionData.endTime}`);
      endTimeFromDate = endTimeAsDate.getTime();
    }

    if (startTimeFromDate && endTimeFromDate && startTimeFromDate >= endTimeFromDate) {
      this.startTimeError = FormValidationError.MAX;
      this.startTimeErrorMessage = this.screenText.getScreenText('SESSION_ADD_EDIT_START_TIME_MAX_ERROR');
      this.endTimeError = FormValidationError.MIN;
      this.endTimeErrorMessage = this.screenText.getScreenText('SESSION_ADD_EDIT_END_TIME_MIN_ERROR');
    }
    else {
      if (this.startTimeError !== FormValidationError.FORMAT) {
        this.startTimeError = null;
        this.startTimeErrorMessage = '';
      }
      if (this.endTimeError !== FormValidationError.FORMAT) {
        this.endTimeError = null;
        this.endTimeErrorMessage = '';
      }
    }
  }

  @Watch('startTime')
  onStartTimeChange() {
    this.validateStartEndTimes();
  }

  @Watch('endTime')
  onEndTimeChange() {
    this.validateStartEndTimes();
  }

  public setSessionDays({ selectedValue = [] }: { selectedValue?: { id: number; value: string; checked: boolean } | { id: number; value: string; checked: boolean }[] }) {
    const values = Array.isArray(selectedValue)
      ? selectedValue
      : [selectedValue];
    this.sessionData.selectedDays = values.map(({ value }) => value);
  }
}
